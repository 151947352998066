// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import $ from 'jquery';
global.$ = jQuery;

import 'foundation-sites/dist/js/foundation';
import 'lightbox2/dist/js/lightbox';
import 'jquery-lazyload/jquery.lazyload.js'

$(document).on('turbolinks:load', function() {
    $(document).foundation();

    $(window).trigger('load.zf.sticky');

        $(function() {
          $(window).scroll(function() {
            var winTop = $(window).scrollTop();
            if (winTop >= 69) {
              $("header").addClass("sticky-header");
            } else{
              $("header").removeClass("sticky-header");
            }
          });
        });

        $(function() {
          $(window).scroll(function() {
            var winTop = $(window).scrollTop();
            if (winTop >= 900) {
              $("#brand_navigation").addClass("sticky-brand_navigation");
            } else{
              $("#brand_navigation").removeClass("sticky-brand_navigation");
            }
          });
        });

        $(document).ready(function(){
            $(this).scrollTop(0);
        });

      // LISTES DEROULANTES DYNAMIQUES
    $('#equipment_brand_id').change(function(){
        var brand_id = this.value
        if(brand_id != "undefined" && brand_id != 0)
        {
            $.ajax({
                url : "/configuration/values/brands/list_brand_models/"+brand_id,
            })
        }
    });

    const iframe = document.querySelector('#iframe_marketbook');

    if(iframe !== null) {
        iframe.onload = function(){
           $('.iframe-container').remove();
        }
    }


    $(function() {
      $('.g-recaptcha').attr("data-callback", "recaptcha_callback");
    })

    function recaptcha_callback()
    {
        document.querySelector('#recaptcha_submit').disabled = false;
    }
    window.recaptcha_callback = recaptcha_callback;


    $("img.lazy").lazyload();



    window.move_miniature_scroll = function(id) {
        var inputs = document.getElementsByClassName('bullet_miniature');

        for (let item of inputs) {
            $(item).removeClass("selected_miniature")
        }

        var el = document.getElementById("orbit_bullet_container");
        var scroll = document.getElementById("scroll_id");
        var offset = 0

        var miniature = document.getElementById("miniature_photo_equipement_button_"+id);

        if(scroll.dataset.id == 'undefined')
        {
            scroll.dataset.id = 0
        }

        if(id > scroll.dataset.id)
        {
            offset = id - scroll.dataset.id
            el.scrollLeft = el.scrollLeft +  (130 * offset)
        }

        if(id < scroll.dataset.id)
        {
            offset =  scroll.dataset.id - id
            el.scrollLeft = el.scrollLeft - (130 * offset)
        }

        $("miniature_photo_equipement_button_"+id).addClass("selected_miniature")

        // Last photo
        scroll.dataset.id = id
    }

});

import "controllers"
